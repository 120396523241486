import React from 'react'

const CreateQuestion = ({onChangeForm, askQuestion }) => {

    return(
        <div className="container">
            <div className="row">
                <div className="col-md-24 mrgnbtm">
                Ask me anything related to NFL rosters.
                <form>
                    <div className="row">
                        <div className="form-group col-md-24">
                            <label htmlFor="exampleInputPassword1">Passkey</label>
                            <input type="password" onChange={(e) => onChangeForm(e)} className="form-control" name="passkey" id="passkey" placeholder="***" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-28">
                            <label htmlFor="exampleInputEmail1">Question</label>
                            <textarea class="form-control" rows="3" onChange={(e) => onChangeForm(e)} name="question" id="question" placeholder="Who are the top three highest paid players on the niners?"></textarea>
                        </div>
                    </div>
                    <button type="button" onClick= {(e) => askQuestion()} className="btn btn-danger">Ask</button>
                </form>
                </div>
            </div>
        </div>
    )
}

export default CreateQuestion