// import React, { useState, useEffect } from 'react';
// import previousQuestion from '../App'
// import {toast} from 'react-toastify'; 
// import 'react-toastify/dist/ReactToastify.css'; 
//   
// toast.configure() 

// let perviousQuestion = "";

export async function askQuestion(data,previousQuestion) {
    // return {"question": "who are the top 5 bucks players?", "answer": "The top 5 Bucks players are: \n1. Player Name: Player1, Cash Value: $1,000,000, Team: Bucks, Position: Position1\n2. Player Name: Player2, Cash Value: $900,000, Team: Bucks, Position: Position2\n3. Player Name: Player3, Cash Value: $800,000, Team: Bucks, Position: Position3\n4. Player Name: Player4, Cash Value: $700,000, Team: Bucks, Position: Position4\n5. Player Name: Player5, Cash Value: $600,000, Team: Bucks, Position: Position5"}
    // return {"message": "passkey invalid"}

    if(!data.passkey){
        alert("Passkey required.");
        // toast('Passkey required');
        return;
    }
    if(!data.question){
        alert("Question required.");
        // toast('Question required');
        return;
    }

    if(previousQuestion){
        if(previousQuestion == data.question){
            console.log(previousQuestion.question);
            console.log(data.question);
            alert("You just asked that question.");
            return;
        }
    }
    data.q = data.question;
    // alert(data.passkey);
    console.log(data);
    const response = await fetch(`https://api.rosterchat.ai`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(data)
      })
    // alert('making the fetch');
    // const response = await fetch('/api/users');
    return await response.json();
    // console.log("here is the data");
    // console.log(data);
    
}