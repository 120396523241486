import React from 'react'

export const Header = () => {

    const headerStyle = {

        width: '100%',
        padding: '2%',
        backgroundColor: "#344358",
        color: 'orange',
        fontSize: '10vw',
        fontWeight: 'bolder',
        fontFamily: 'Arial, Helvetica, sans-serif',
        textShadow: '2px 2px #696969',
        textAlign: 'center'
    }

    return(
        <div class="text-xl-start" style={headerStyle}>
            {/* <img src="http://rostermanagementsystem.com/img/logo.png"/> */}
            RosterChat
        </div>
    )
}