import React, {useRef, useEffect} from 'react'

export const AnswerDisplay = ({chatHistory}) => {

    const headerStyle = {
        height: '300px',
        // border: 'black dashed 2px',
        display: 'flex',
        flexDirection: 'column-reverse',
        overflow: 'auto',
        // padding: '1%',
        // backgroundColor: "#ffffff",
        // color: 'white',
        textAlign: 'left'
    }

    // console.log('now here is the chatHistory length:::', chatHistory.length)
    // console.log('now here is the chatHistory period:::', chatHistory)
    if (chatHistory.length === 0) return null

    const HistoryRow = (history,index) => {
        console.log("history row")
        return(
              <tr>
                <td>
                <table className="table table-sm table-borderless">
                    <tr>
                  <td>
                    {history.question}
                  </td>
                  </tr>
                  <tr>
                    <td>
                    {history.answer}
                    </td>
                  </tr>
                  </table>
                  </td>
              </tr>
          )
    }

    const historyTable = chatHistory.map((history,index) => HistoryRow(history,index))
    // console.log("here is the history table")
    // console.log(historyTable)
    return(
        <div style={headerStyle} className="overflow-auto">
            <table className="table table-sm">
                <tbody>
                    {historyTable}
                </tbody>
            </table>
        </div>
    )
}