import React from 'react'

export const Footer = () => {

    const footerStyle = {
        position: 'fixed',
        bottom: '0',
        width: '100%',
        padding: '2%',
        backgroundColor: "#344358",
        color: 'white',
        textAlign: 'center'
    }

    return(
        <div style={footerStyle}>
            © 2023 Roster Management System
        </div>
    )
}