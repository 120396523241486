import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Header } from './components/Header'
import { Footer } from './components/Footer'
import { AnswerDisplay } from './components/AnswerDisplay'
import CreateQuestion from './components/CreateQuestion'
import { askQuestion } from './services/AskQuestionService'

function App() {

  const [question, setQuestion] = useState({})
  const [previousQuestion, setPreviousQuestion] = useState("")
  const [numberOfRequests, setNumberOfRequests] = useState(0)
  const [chatHistory, setChatHistory] = useState([])
  // var previousQuestion = {}

  const AskQuestion = (e) => {

    askQuestion(question, previousQuestion)
        .then(response => {
          if(response){
            var newAnswer = chatHistory;
            if(response.message){
              response.answer = response.message;
            }
            newAnswer.push({"question": question.question, "answer": response.answer});   
            setChatHistory(newAnswer);
            // for some reason this triggers the refresh of chat history so keep it in 
            setNumberOfRequests(numberOfRequests+1)  
            console.log("setting the previous now")
            // var prevQ = question;
            setPreviousQuestion(question.question)
            // let previousQuestion = question;
            console.log("ok?");
            console.log(previousQuestion)
          }
      });
  }

  // const fetchAllUsers = () => {
    // getAllUsers()
      // .then(users => {
        // console.log(users)
        // setUsers(users);
        // setNumberOfUsers(users.length)
      // });
  // }

  // useEffect(() => {
    // getAllUsers()
      // .then(users => {
        // console.log(users)
        // setUsers(users);
        // setNumberOfUsers(users.length)
      // });
  // }, [])

  const onChangeForm = (e) => {
      if (e.target.name === 'passkey') {
        question.passkey = e.target.value;
      } else if (e.target.name === 'question') {
        question.question = e.target.value;
      }
      setQuestion(question)
  }
  
    
    return (
        <div className="App">
          <Header></Header>
          <div className="container mrgnbtm">
            <div className="row">
              <div className="col-md-6">
                <AnswerDisplay chatHistory={chatHistory}></AnswerDisplay>
              </div>
              <div className="col-md-6">
                  <CreateQuestion
                    question={question}
                    onChangeForm={onChangeForm}
                    askQuestion={AskQuestion}
                    >
                  </CreateQuestion>
              </div>
            </div>
          </div>
          <Footer></Footer>
        </div>
    );
}

export default App;
