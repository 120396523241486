import React from 'react';
import ReactDOM from 'react-dom';
// Importing toastify module
// import {toast} from 'react-toastify'; 
 
// Import toastify css file
// import 'react-toastify/dist/ReactToastify.css';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { AppError } from './components/AppError';

// toast-configuration method, 
// it is compulsory method.
// toast.configure()

ReactDOM.render(
  <React.StrictMode>
    <AppError>
      <App />
    </AppError>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
